.user-avatar {
    background: no-repeat center center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    background-size: auto 100%;
    border: 1px solid #eaeaea;
}

.user-avatar:hover {
    cursor: pointer;
    border: 1px solid #9b9b9b;
}

header .user-avatar {
    background: no-repeat center center;
    margin: 10px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
    background-size: auto 100%;
    border: 1px solid #eaeaea;
}

.user-avatar-text {
    border: 1px solid #eaeaea;
    position: relative;
    align-self: center;
    font-size: 11px;
    font-weight: bold;

}

.user-avatar-badge {
    font-weight: normal;
    flex: 0 0 auto !important;
}