.tracker .horizontal-scroller-wrapper {
    --tracker-column-bg: #ffffff;
    --tracker-column-border: #c1c5c8;
    --tracker-column-color: #343a40;
    --tracker-column-title-bg: #eaeaea;

    --tracker-task-bg: #ffffff;
    --tracker-task-border: #c1c5c8;
    --tracker-task-color: #343a40;
    --tracker-task-fw: normal;
    --tracker-task-title-fw: 600;

    --tracker-task-datetime-bg: #ffffff;
    --tracker-task-datetime-border: #c1c5c8;

    --tracker-scrollbar-track: #f6f6f6;
    --tracker-scrollbar-thumb: #dadada;
}

.dark-theme .tracker .horizontal-scroller-wrapper {
    --tracker-column-bg: #23272c;
    --tracker-column-border: #17191c;
    --tracker-column-color: #ffffff;
    --tracker-column-title-bg: #343a40;

    --tracker-task-bg: #353b41;
    --tracker-task-border: #17191c;
    --tracker-task-color: #ffffff;
    --tracker-task-fw: 300;
    --tracker-task-title-fw: normal;

    --tracker-task-datetime-bg: #23272c;
    --tracker-task-datetime-border: #17191c;

    --tracker-scrollbar-thumb: #3b4249;
    --tracker-scrollbar-track: #4f5760;
}


.horizontal-scroller-wrapper {
    display: flex;
    /*border: 5px solid #444444;*/
}

.horizontal-scroller {
    height: calc(100vh - 187px);
    overflow-x: scroll;
    /*border: 4px solid orange;*/
}

.horizontal-scroller::-webkit-scrollbar {
    height: 10px;
}

.horizontal-scroller::-webkit-scrollbar-track {
    background-color: var(--tracker-scrollbar-track);
    border-radius: 5px;
}

.horizontal-scroller::-webkit-scrollbar-thumb {
    border-radius: 15px;
    border: 2px solid transparent;
    background-clip: content-box;
    background-color: var(--tracker-scrollbar-thumb);
}

.horizontal-scroller [data-rbd-droppable-id="all-columns"] {
    /*border: 5px solid #444444;*/
    padding: 0 15px;
    width: auto;
    display: inline-flex;
    align-items: flex-start;
    /*gap: 15px; Bad practice, needs padding instead*/
}


.tracker-column {
    margin-right: 15px;
    min-width: 300px !important;
    max-width: 300px !important;
    padding: 0;
    border: 1px solid var(--tracker-column-border);
    border-radius: 5px;
    min-height: 100px;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
    background: var(--tracker-column-bg);
}

.tracker-column-overflow {
    overflow-y: auto;
    max-height: calc(100vh - 320px);
}

.tracker-column-overflow::-webkit-scrollbar {
    width: 10px;
}

.tracker-column-overflow::-webkit-scrollbar-track {
    background-color: var(--tracker-scrollbar-track);
    border-radius: 5px;
}

.tracker-column-overflow::-webkit-scrollbar-thumb {
    border-radius: 15px;
    border: 2px solid transparent;
    background-clip: content-box;
    background-color: var(--tracker-scrollbar-thumb);
}


.column-add-column {
    min-height: 0;
    padding: 5px;
}

.column-add-column .form-group {
    margin-bottom: 0;
}

.tracker-column-title {
    text-transform: uppercase;
    position: relative;
    padding: 5px;
    margin: 5px 5px 0 5px;
    background: var(--tracker-column-title-bg);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: var(--tracker-column-color);
}

.tracker-column-card-title {
    font-size: 18px;
    font-weight: var(--tracker-task-title-fw);
}

.tracker-column-card {
    width: 100%;
    margin-top: 5px;
    padding: 15px;
    border: 1px solid var(--tracker-task-border);
    border-radius: 5px;
    font-size: 15px;
    font-weight: var(--tracker-task-fw);
    background: var(--tracker-task-bg);
    color: var(--tracker-task-color);
}

.tracker-column-card:first-child {
    margin-top: 0;
}

.tracker-column-card-form {
    position: relative;
    overflow: hidden;
    margin: 5px;
    border: 1px solid #000000;
    padding: 1rem 1rem 0 1rem;
    border-radius: 4px;

    background: repeating-linear-gradient(
            -55deg,
            #999,
            #999 10px,
            #888 10px,
            #888 20px
    );

    -moz-box-shadow: inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow: inset 0 0 10px #000000;
}

.tracker-column-remove-card {
    cursor: pointer;
    position: absolute;
    overflow: hidden;

    height: 23px;
    width: 49px;
    top: -7px;
    right: -19px;

    -moz-box-shadow: -1px -1px 10px #000000;
    -webkit-box-shadow: -1px -1px 10px #000000;
    box-shadow: -1px -1px 10px #000000;
    padding-top: 7px;
    background: #cccccc;
    transform: rotate(45deg);
    text-align: center;
}

.tracker-column-remove-card div {
    transform: rotate(-45deg);
    font-size: 12px;
}

.tracker-column-remove-card:hover {
    background: #eaeaea;
}


.task-list {
    min-height: 1px;
    padding: 0 5px 5px 5px;
}

.tracker-column-card.border-danger, .tracker-column-card.border-warning,
.tracker-column-card.border-success {
    border-left: 5px solid;
}


.example-enter {
    opacity: 0.01;
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.example-leave {
    opacity: 1;
}

.example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.shift-mouse-scroll {

}

.task-datetime {
    position: absolute;
    right: -15px;
    top: -15px;
    border: 1px solid var(--tracker-task-datetime-border);
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-top: 0;
    border-right: 0;
    font-size: 12px;
    font-weight: 500;
    background: var(--tracker-task-datetime-bg);
}

.add-card-button {
    margin-left: 5px;
    margin-right: 5px;
}