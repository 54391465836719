.theme {
    width: 45px;
    height: 45px;
    text-align: center;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
}

.theme:hover {
    background: #eaeaea;
}

.theme .theme-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}