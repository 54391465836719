.relax-win {
    --win-board-bg-color: #ffffff;
}

.dark-theme .relax-win {
    --win-board-bg-color: #23272c;
}

.relax-win {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    background: var(--win-board-bg-color);
    width: 100%;
    height: 100%;
    border-radius: 3px;
    overflow: hidden;
}

.relax-win-word {
    font-size: 50px;
    line-height: 45px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #009a00;
}


.win-particle-dots {
    pointer-events: none;
    position: absolute;
    transition: all 1000ms;
    transition-timing-function: linear;
    border-radius: 50%;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 1s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
