.custom-info-light {
    --color: #444444;
    --background-color: #f5f5f5;
    --border-color: #eaeaea;
}

.dark-theme .custom-info-light {
    --color: #eaeaea;
    --background-color: #2e3338;
    --border-color: #192025;
}

.custom-info {
    border-radius: 3px;
    padding: 10px 15px;
}

.custom-info-light {
    color: var(--color);
    background-color: var(--background-color);
    border: 1px solid var(--border-color) !important;
}

