.healthcheck-result {
    color: var(--main-text-color);
    padding: 15px;
}

.healthcheck-result-system-info {
    position: relative;
    border: 1px solid var(--dashed-border-color);
    border-radius: 10px;
    padding: 15px;
    color: var(--main-text-color) !important;
}

.healthcheck-result-title {
    position: relative;
    z-index: 1;
}

.healthcheck-logo-placement {
    position: absolute;
    width: 120px;
    height: 120px;
    top: -60px;
    right: 30px;
    background: var(--main-content-background);
    border-radius: 100%;
    z-index: 0;
}

.healthcheck-logo-placement svg {
    width: 80%;
    height: 80%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@container (max-width: 450px) {
    .healthcheck-logo-placement {
        display: none;
    }
}

.healthcheck-result-system-info-content {
    position: relative;
    z-index: 1;
}

.hc-si-row {
    position: relative;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 3fr;
}

.hc-si-row::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed var(--dashed-border-color);
    opacity: var(--dashed-border-opacity);
}

.hc-si-row:last-child::after {
    opacity: 0;
}

.hc-si-cell {
    white-space: nowrap;
}

.hc-ss-report-epilogue,
.hc-ss-report-prologue {
    font-size: 13px;
    margin-top: 30px;
}

.hc-ss-report {
    margin-top: 30px;
    max-width: 500px;
}

.hc-ss-row {
    display: grid;
    gap: 10px;
    grid-template-columns: 3fr auto;
}

.hc-ss-cell {
    position: relative;
    background: var(--main-content-background);
}

.hc-ss-cell:first-child::after {
    content: " ";
    margin-left: 10px;
    border-bottom: 1px dashed var(--dashed-border-color);
    opacity: var(--dashed-border-opacity);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
}


@media print {
    body * {
        visibility: hidden;
    }

    body,
    .light-theme,
    .dark-theme,
    .main-container,
    #root,
    .main-container-aside,
    .main-content-wrapper,
    .main-content {
        height: auto !important;
    }

    .header {
        display: none !important;
    }

    .main-container-aside {
        display: none !important;
    }

    .main-container {
        grid-template-columns: 1fr !important;
    }

    .main-content * {
        visibility: visible !important;
    }

    .main-content {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
        width: 100% !important;
        container-type: normal !important;
        overflow: visible !important;
    }

    .hc-ss-cell,
    .healthcheck-logo-placement {
        background: #ffffff !important;
    }

    .hc-ss-report {
        max-width: 100%;
    }

    .logo-under-bar,
    .hc-save-as-pdf,
    .hc-bottom-line,
    .report-filters,
    .back-to-report-list {
        display: none;
    }

}


.system-eval-result {
    color: var(--main-text-color);
    padding: 15px;
}

.system-eval-result-system-info {
    position: relative;
    border: 1px solid var(--dashed-border-color);
    border-radius: 10px;
    padding: 15px;
    color: var(--main-text-color) !important;
}

.system-eval-result-title {
    position: relative;
    z-index: 1;
}

.healthcheck-logo-placement {
    position: absolute;
    width: 120px;
    height: 120px;
    top: -60px;
    right: 30px;
    background: var(--main-content-background);
    border-radius: 100%;
    z-index: 0;
}

.healthcheck-logo-placement svg {
    width: 80%;
    height: 80%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@container (max-width: 450px) {
    .healthcheck-logo-placement {
        display: none;
    }
}

.system-eval-result-system-info-content {
    position: relative;
    z-index: 1;
}

.si-row {
    position: relative;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 3fr;
}

.si-row::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed var(--dashed-border-color);
    opacity: var(--dashed-border-opacity);
}

.si-row:last-child::after {
    opacity: 0;
}

.si-cell {
    white-space: nowrap;
}

.ss-report-epilogue,
.ss-report-prologue {
    font-size: 13px;
    margin-top: 30px;
}

.ss-report {
    margin-top: 30px;
    max-width: 500px;
}

.ss-row {
    display: grid;
    gap: 10px;
    grid-template-columns: 3fr auto;
}

.ss-cell {
    position: relative;
    background: var(--main-content-background);
}

.ss-cell:first-child::after {
    content: " ";
    margin-left: 10px;
    border-bottom: 1px dashed var(--dashed-border-color);
    opacity: var(--dashed-border-opacity);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
}

.print-powered-by {
    display: none;
    justify-content: space-between;
    pointer-events: initial;
    vertical-align: center;
}

.print-powered-by svg {
    filter: invert(32%) saturate(1348%) hue-rotate(346deg) brightness(95%) contrast(85%);
}

@media print {
    .print-powered-by {
        display: flex;
    }

    body * {
        visibility: hidden;
    }

    body,
    .light-theme,
    .dark-theme,
    .main-container,
    #root,
    .main-container-aside,
    .main-content-wrapper,
    .main-content {
        height: auto !important;
    }

    .header {
        display: none !important;
    }

    .main-container-aside {
        display: none !important;
    }

    .main-container {
        grid-template-columns: 1fr !important;
    }

    .main-content * {
        visibility: visible !important;
    }

    .main-content {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
        width: 100% !important;
        container-type: normal !important;
        overflow: visible !important;
    }

    .ss-cell,
    .healthcheck-logo-placement {
        background: #ffffff !important;
    }

    .ss-report {
        max-width: 100%;
    }

}























