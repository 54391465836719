.devices-list,
.devices-list-tooltip {
    --devices-list-row-bg: #ffffff;
    --devices-list-row-border: #ffffff;
    --devices-list-row-color: #444444;
    --devices-list-row-numbering: #ab8383;

    --tooltip-inner-border: #cccccc;
    --tooltip-arrow-border: #cccccc;

    --devices-list-filters-bg: #ffffff;
    --devices-list-filters-border: #cccccc;
    --devices-list-filters-color: #444444;

    --devices-list-inner-track: #f6f6f6;
    --devices-list-inner-thumb: #999999;

}

.dark-theme .devices-list,
.devices-list-tooltip.result-tooltip--dark {
    --devices-list-row-bg: #343a40;
    --devices-list-row-border: #56606a;
    --devices-list-row-color: #ffffff;
    --devices-list-row-numbering: #e76529;

    --tooltip-inner-border: #56606a;
    --tooltip-arrow-border: #56606a;

    --devices-list-filters-bg: #60656b;
    --devices-list-filters-border: #3c434b;
    --devices-list-filters-color: #ffffff;

    --devices-list-inner-thumb: #3b4249;
    --devices-list-inner-track: #4f5760;
}

.devices-list-inner {
    overflow: auto;
}

.devices-list-device-row-head {
    border-radius: 5px 5px 0 0 !important;
    border-bottom: 4px solid var(--devices-list-row-numbering) !important;
}

.devices-list-device-row-head * {
    font-weight: bold;
    color: var(--devices-list-row-color) !important;
}

.devices-list-device-row {
    background: var(--devices-list-row-bg);
    border: 1px solid var(--devices-list-row-border);
    color: var(--devices-list-row-color);
    border-radius: 5px;
    margin-bottom: 15px;
    box-shadow: 0 0 9px -4px rgba(48, 51, 17, 0.50);
    -webkit-box-shadow: 0 0 9px -4px rgba(48, 51, 17, 0.50);
    -moz-box-shadow: 0 0 9px -4px rgba(48, 51, 17, 0.50);
}

.devices-list-device-items {
    flex: 1 0;
    align-self: center;
    padding: 10px;
}

.devices-list-device-items-numbers {
    flex: 0 0 50px;
    font-size: 32px;
    line-height: 32px;
    font-style: italic;
    font-weight: bolder;
    color: var(--devices-list-row-numbering);
}

.devices-list-device-items-qr {
    flex: 0 0 50px;
    text-align: center;
}

.devices-list-device-items-global-status {
    flex: 0 0 150px;
}

.devices-list-device-items-public-url {
    flex: 0 0 250px;
}

.copy-clipboard {
    cursor: pointer;
}

.copy-clipboard:hover {
    color: var(--devices-list-row-numbering);
}

.devices-list-major-tests {
    display: flex;
    gap: 5px;
}


.devices-list-major-tests img {
    max-height: 19px;
    width: 100%;
    filter: brightness(0) invert(1);

    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Safari */
    image-rendering: optimize-contrast; /* CSS3 Proposed */
    -ms-interpolation-mode: nearest-neighbor; /* IE8+ */
}

.devices-list-major-tests-item {
    flex: 0 0 50px;
    border-radius: 5px;
    padding: 2px 3px;
    border: 1px solid;
    position: relative;
}

.devices-list-tooltip .tooltip-inner {
    background-color: var(--devices-list-row-bg);
    border: 2px solid var(--tooltip-inner-border);
    color: var(--devices-list-row-color);
    padding: 10px;
}

.devices-list-tooltip .tooltip-arrow::before {
    border-bottom-color: var(--tooltip-inner-border);
}

@media (max-width: 600px) {
    .devices-list-device-items:nth-child(3), .devices-list-device-items:nth-child(4), .devices-list-device-items:nth-child(5), .devices-list-device-items:nth-child(6) {
        display: none;
    }
}

.devices-list-inner::-webkit-scrollbar {
    width: 10px;
}

.devices-list-inner::-webkit-scrollbar-track {
    background-color: var(--devices-list-inner-track);
}

.devices-list-inner::-webkit-scrollbar-thumb {
    border-radius: 15px;
    border: 2px solid transparent;
    background-clip: content-box;
    background-color: var(--devices-list-inner-thumb);
}