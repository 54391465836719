.uav {
    --uav-item-bg: #ffffff;
    --uav-item-border: #c1c5c8;
    --uav-item-color: #343a40;
    --uav-info-item-bg: #eaeaea;

    --uav-scrollbar-track: #e34600;
    --uav-scrollbar-thumb: #f5f5f5;
}

.dark-theme .uav {
    --uav-item-bg: #23272c;
    --uav-item-border: #17191c;
    --uav-item-color: #ffffff;
    --uav-info-item-bg: #343a40;

    --uav-scrollbar-thumb: #3b4249;
    --uav-scrollbar-track: #4f5760;
}

.uav .system-info-page-heading {
    background: linear-gradient(-50deg, #e0e0e0 15%, #7a7a7a 15%);
    color: white;
}

.uav .system-info-page-heading img {
    height: 30px;
}

.uav-badge-statuses .badge {
    font-weight: normal;
    margin-right: 5px;
}

.uav-badge-statuses .badge b {
    font-weight: 600;
}

.uav-screen {
    border: 1px solid var(--uav-item-border);
    border-radius: 3px;
    background: var(--uav-item-bg);
    color: var(--uav-item-color);
}

.uav-info-items {
    width: 100%;
    padding: 15px;
    border: 1px solid var(--uav-item-border);
    border-radius: 3px;
    background: var(--uav-info-item-bg);
    color: var(--uav-item-color);
}