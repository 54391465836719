.wstpusb {
    --wstpusb-item-bg: #ffffff;
    --wstpusb-item-border: #c1c5c8;
    --wstpusb-item-color: #343a40;
    --wstpusb-item-inside-bg: #eaeaea;


    --wstpusb-scrollbar-track: #e34600;
    --wstpusb-scrollbar-thumb: #f5f5f5;
}

.dark-theme .wstpusb {
    --wstpusb-item-bg: #23272c;
    --wstpusb-item-border: #17191c;
    --wstpusb-item-color: #ffffff;
    --wstpusb-item-inside-bg: #343a40;


    --wstpusb-scrollbar-thumb: #3b4249;
    --wstpusb-scrollbar-track: #4f5760;
}

.wstpusb .system-info-page-heading {
    background: linear-gradient(-50deg, #e0e0e0 15%, #7a7a7a 15%);
}

.wstpusb .system-info-page-heading img {
    height: 30px;
}

.wstpusb-badge-statuses .badge {
    font-weight: normal;
    margin-right: 5px;
}

.wstpusb-badge-statuses .badge b {
    font-weight: 600;
}

.wstpusb-test-items {
    margin: 15px 0;
    background: var(--wstpusb-item-bg);
    border: 1px solid var(--wstpusb-item-border);
    border-radius: 3px;
    width: 100%;
    padding: 15px;

    gap: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.wstpusb-test-items .wstpusb-test-item {
    flex: 1 0 auto;
    width: calc(50% - 15px);
    flex-direction: column;
    border: 1px solid var(--wstpusb-item-border);
    padding: 15px;
    border-radius: 3px;
    background: var(--wstpusb-item-inside-bg);
    color: var(--wstpusb-item-color);
}

.wstpusb-test-item-processor img,
.wstpusb-test-item-device img,
.wstpusb-test-item-mainboard img,
.wstpusb-test-item-ram img,
.wstpusb-test-item-multimedia img,
.wstpusb-test-item-network img {
    width: 50px;
}

.wstpusb-test-item-flex-inside {
    display: flex;
    justify-content: space-between;
}

.wstpusb-test-item-flex-inside * {
    flex: 1 0;
}

.wstpusb-test-item-flex-heading {
    background: #7a7a7a;
    border-radius: 3px;
    padding: 2px 15px;
    color: #eaeaea;
    justify-content: space-between;
}

.wstpusb-test-item-flex-body {
    padding: 2px 15px;
}

.wstpusb-multimedia {

}

.wstpusb-multimedia-items {
    width: 33.33%
}

.wstpusb-multimedia-item-flex {
    flex: 1 0;
}

.wstpusb-multimedia-items-icon {
    background: #7a7a7a;
    width: 70px;
    padding: 5px;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 3px 3px 0 0;
}

.wstpusb-multimedia-items-icon img {
    width: 100%;
}


.wstpusb-multimedia-items-icon:before {
    content: "";
    background: var(--wstpusb-item-inside-bg);
    height: 3px;
    width: 7px;
    position: absolute;
    left: -7px;
    bottom: 0;
    border-bottom-right-radius: 5px;
    box-shadow: 3px 0 0 0 #7a7a7a;
}

.wstpusb-multimedia-items-icon:after {
    content: "";
    background: var(--wstpusb-item-inside-bg);
    height: 3px;
    width: 7px;
    position: absolute;
    right: -7px;
    bottom: 0;
    border-bottom-left-radius: 5px;
    box-shadow: -3px 0 0 0 #7a7a7a;
}