.sessions-list-filters,
.sessions-list-filters-tooltip {
    --sessions-list-filters-bg: #ffffff;
    --sessions-list-filters-border: #cccccc;
    --sessions-list-filters-color: #444444;
}

.dark-theme .sessions-list-filters,
.sessions-list-filters-tooltip.result-tooltip--dark {
    --sessions-list-filters-bg: #60656b;
    --sessions-list-filters-border: #3c434b;
    --sessions-list-filters-color: #ffffff;
}


.sessions-list-filters .form-select {
    background-color: var(--sessions-list-filters-bg);
    border: 1px solid var(--sessions-list-filters-border);
    color: var(--sessions-list-filters-color);
}

.sessions-list-filters .form-label {
    color: var(--sessions-list-filters-color);
}