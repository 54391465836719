.circular-progress {
    page-break-inside: avoid;
    position: relative;
    margin: 0 auto;
    height: 250px;
    width: 250px;
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(53, 32, 119, 0.1);

    display: flex;
    align-items: center;
    justify-content: center;
}

.circular-progress-before {
    content: "";
    position: absolute;
    height: 180px;
    width: 180px;
    border-radius: 50%;
    box-shadow: inset 0 0 15px rgba(53, 32, 119, 0.2);
    transition: background-color ease-in-out 200ms;
}

.progress-value {
    position: relative;
    color:#7d2ae8;
    font-size:38px;
}