aside {
    --aside-background-color: #343a40;
    --aside-header-background-color: #343a40;
    --aside-header-border-top: #4b545c;
    --aside-general-text: #ffffff;

    height: 100%;
    box-shadow: 1px 1px 11px -1px rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 1px 1px 11px -1px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 1px 1px 11px -1px rgba(0, 0, 0, 0.7);
    position: relative;
    z-index: 1;
}

.aside-wrapper {
    background: var(--aside-background-color);
    height: 100%;
    color: var(--aside-general-text);
}

.aside {
    position: sticky;
    top: 0;
}

.aside-header {
    position: relative;
    text-align: center;
    background: var(--aside-header-background-color);
    width: 100%;
    height: 85px;
    overflow: hidden;
    border-bottom: 1px solid var(--aside-header-border-top);
    padding: 0 15px;
}

.aside-closed-false {
    width: 300px;
    transition: width ease-in-out 500ms;
    overflow: hidden;
}

.aside-closed-true {
    width: 120px;
    transition: width ease-in-out 500ms;
    overflow: hidden;
}

.admin-company-logo {
    max-height: 75px;
}

.admin-company-logo img {
    height: 65px;
    max-width: 270px;
}

.search-bar .form-control,
.search-bar .btn {
    background-color: #3f474e;
    border: 1px solid #56606a;
    color: #fff;
}

/*#######################*/
/*#######[ MOBILE ]######*/
@media (max-width: 600px) {
    .aside-closed-true {
        width: 0;
    }
}