.hardware-inventory-content {
    --system-info-numbering: #bebebe;
    --system-info-td-bg: #eaeaea;
    --system-info-block-border: #eaeaea;
    --logical-drive-bg: #CCBCB5;
}

.dark-theme .hardware-inventory-content {
    --system-info-td-bg: #2e3338;
    --system-info-block-border: #2e3338;
    --logical-drive-bg: #2a211d;

}


.hardware-inventory-content .install-date {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 3px 0 3px;
}

.hardware-inventory-content .small {
    line-height: 15px;
    margin-left: auto;
}

.hardware-inventory-content .drive-size {
    font-size: 22px;
    line-height: 23px;
    color: #e34600;
}

.hardware-inventory-content .drive-size small {
    font-size: 12px;
    color: #e34600;
    font-style: italic;
}

.hardware-inventory-content .logical-drive {
    padding: 15px 10px;
    border-radius: 5px;
    background: var(--logical-drive-bg);
    width: 100%;
    max-width: 500px;
    margin-top: 20px;
}

.hardware-inventory-content .logical-drive .progress {
    height: 6px;
    background: #F2EEED;
    border-radius: 1px;
}

.hardware-inventory-content .logical-drive .bg-orange {
    background: #7e6040;
}

.hardware-inventory-content .drive-format-type {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 5px 0 5px;
}

.hardware-inventory-content .gpu-name {
    font-size: 22px;
    line-height: 23px;
    color: #28a745;
}

.hardware-inventory-content .cpu-name {
    font-size: 22px;
    line-height: 23px;
    color: #007bff;
}

.hardware-inventory-content .mainboard-name {
    font-size: 22px;
    line-height: 23px;
    color: #17a2b8;
}

.smbios-system-name {
    position: relative;
    font-weight: bold;
    font-size: 32px;
}

.smbios-system-manufacturer {
    font-weight: normal;
    position: absolute;
    font-size: 15px;
    text-align: right;
    right: 0;
    bottom: -12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 100%;
}

.smbios-system-manufacturer img {
    vertical-align: middle;
    max-height: 20px;
    position: relative;
    top: -2px;
}

.dark-theme .smbios-system-manufacturer img {
    filter: invert(60%) sepia(80%);
}

