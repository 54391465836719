.tracker-priority-tasks {
    --win-board-bg-color: #ffffff;
    --dash-priority-tasks-h-color: #444444;
}

.dark-theme .tracker-priority-tasks {
    --win-board-bg-color: #23272c;
    --dash-priority-tasks-h-color: #cccccc;
}

.tracker-priority-tasks-wrapper {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.tracker-priority-tasks .horizontal-scroller-wrapper {
    gap: 15px;
    flex-wrap: wrap;
}

.tracker-priority-tasks .horizontal-scroller-wrapper {

}

.tracker-priority-tasks h5 {
    color: var(--dash-priority-tasks-h-color)
}

.dash-tasks {
    display: flex;
    flex-wrap: wrap;
}

.dash-task {
    flex: 0 0 calc(25% - 15px);
}

@media (max-width: 600px) {
    .dash-task {
        flex: 0 0 calc(50% - 15px);
    }
}

.tracker-column-card-scroll {
    height: 100px;
    overflow-y: scroll;
}

.priority-task-vertical-scroller::-webkit-scrollbar {
    width: 10px;
}

.priority-task-vertical-scroller::-webkit-scrollbar-track {
    background-color: var(--tracker-scrollbar-track);
    border-radius: 5px;
}

.priority-task-vertical-scroller::-webkit-scrollbar-thumb {
    border-radius: 15px;
    border: 2px solid transparent;
    background-clip: content-box;
    background-color: var(--tracker-scrollbar-thumb);
}