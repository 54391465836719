.guide-document-styles {
    --link-color: orange;
    --border-color: #c7c7c7;
    --border-radius: 5px;
    --border-radius-sm: 3px;
    /*--border-width: 5px;*/
    color: #3D3D3D;
    font-size: 16pt;
}

.dark-theme .guide-document-styles {
    --link-color: orange;
    --border-color: #c7c7c7;
    --border-radius: 5px;
    --border-radius-sm: 3px;
    /*--border-width: 5px;*/
    --color: white;
    color: white;
    font-size: 16pt;
}


.guide-document-styles article.user-guide-chapter {
    max-width: 800px;
    margin: 1rem auto;
    border-top: 1px solid lightgray;
    padding-top: 0.75rem;
    line-height: 1.5;
}

.guide-document-styles article.user-guide-chapter figure img {
    max-width: 100%;
    height: auto;
    border: 1px solid var(--border-color) !important;
    padding: 0.5rem;
    border-radius: var(--border-radius);
}

.guide-document-styles article.user-guide-chapter figcaption {
    line-height: 1.5;
    font-size: 0.8em;
    color: #3d3d3d;
}

.guide-document-styles article.user-guide-chapter figure {

}

.guide-document-styles article.user-guide-chapter div.diagram-list ol {
    column-count: 2;
    justify-content: space-between;
    display: none;
}

.guide-document-styles article.user-guide-chapter kbd {
    background-color: #FBFEF9;
    color: #171515;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    text-align: center;
    min-width: 0.75rem;
    padding: 0.1em 0.4em;
    line-height: 1.25;
    font-size: 1em;
    font-family: inherit;
    display: inline-block;
    cursor: default;
    border: 0.5px solid var(--border-color);
}

.guide-document-styles article.user-guide-chapter h1,
.guide-document-styles article.user-guide-chapter h2,
.guide-document-styles article.user-guide-chapter h3,
.guide-document-styles article.user-guide-chapter h4,
.guide-document-styles article.user-guide-chapter h5 {
    color: black;
    margin: 0 0 0.5em;
}

.guide-document-styles article.user-guide-chapter h2 {
    font-weight: 700;
    color: var(--link-color);
}

.guide-document-styles article.user-guide-chapter h3 {
    font-weight: bold;
    margin-top: 2em;
}

.guide-document-styles article.user-guide-chapter h4 {
    margin-top: 1em;
    color: #3D3D3D;
}

.guide-document-styles article.user-guide-chapter h5 {
    font-size: 1rem;
    margin-bottom: 0;
    display: inline;
}

.guide-document-styles article.user-guide-chapter p,
.guide-document-styles article.user-guide-chapter ul,
.guide-document-styles article.user-guide-chapter ol {
    margin: 0;
}

.guide-document-styles article.user-guide-chapter ul li::before {
    /* This makes list-style: none lists readable for screen readers */
    content: "\200B";
}

.guide-document-styles p ~ p {
    margin-top: 0.75em
}

.guide-document-styles address.company-info {
    margin: 1.5em -1em;
    padding: 1em;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    gap: 0.3em;
    cursor: default;
}

/* This applies a gap between the office address and the contact methods  */
.guide-document-styles address.company-info > span ~ span {
    margin-bottom: 0.75em;
}

.guide-document-styles div.table {
    margin: 1.5rem 0;
}

.guide-document-styles div.table-row {
    display: flex;
    border-top: 1px solid gray;
    gap: 0.5rem;
    align-items: baseline;
}

.guide-document-styles div.table-row:last-child {
    border-bottom: 1px solid gray;
}

.guide-document-styles div.table-row > p:first-child {
    min-width: 15%;
    font-weight: bold;
}

.guide-document-styles div.table-row > p {
    padding: 1rem;
    list-style-type: none;
    width: auto;
}

.guide-document-styles p > img.inline-img {
    /* This effects the 'padding' around the image and will adjust to different font sizes */
    --icon-spacing: 0.2em;

    max-height: calc(1em + var(--icon-spacing));
    width: auto;
    margin: 0 var(--icon-spacing);
    vertical-align: calc(-1 * var(--icon-spacing));
}

.guide-document-styles div.notification {
    /*--border-width: 0.25rem;*/
    --padding: 1.5rem;

    padding: var(--padding);
    color: black;
    display: flex;
    gap: 0.75rem;
    border-left: 0.25rem solid transparent;
    margin: 15px -16px 15px -25px;
}

.guide-document-styles div.warning {
    --warning: 222, 15, 0;

    border-color: rgba(var(--warning), 1.0);
    background-color: rgba(var(--warning), 0.4);
    align-items: center;
}

.guide-document-styles div.techtip {
    --techtip-color: 0, 160, 180;
    --techtip-text-color: 0, 30, 30;

    border-color: rgba(var(--techtip-color), 1.0);
    background-color: rgba(var(--techtip-color), 0.4);
    color: rgba(var(--techtip-text-color), 1.0);
    flex-direction: column;
}

.guide-document-styles div.techtip > p {
    margin-top: 0;
}

.guide-document-styles .techtip-header {
    font-size: 30px !important;
}

.guide-document-styles div.under-construction {
    --under-construction: 252, 152, 3;
    border-color: rgba(var(--under-construction), 1);
    background-color: rgba(var(--under-construction), 0.5);
    align-items: center;
    color: black;
}

.guide-document-styles code.codeblock {
    display: block;
    background-color: rgba(18, 18, 24, 1.0);
    border-radius: 10px;
    color: white;
    font-weight: lighter;
    padding: 1em;
    margin: 1em -1em;
}

.guide-document-styles span.mini-notification {
    background: lightgray;
    font-size: 0.75rem;
    padding: 0.4em;
    margin-left: -0.4em;
    border-radius: 3px;
}

.guide-document-styles article.user-guide-chapter a {
    text-decoration: none;
    color: inherit;
}

.guide-document-styles a:hover span, a.inline-link:hover {
    text-decoration: underline;
}

.guide-document-styles a:link.long-link, a:visited.long-link {
    display: block;
    margin: 1em 0;
}

.guide-document-styles a.long-link > span, a.inline-link {
    color: var(--link-color);
    font-weight: 700;
}

.guide-document-styles a.external-link > span {
    color: blue;
    font-weight: normal;
}

.guide-document-styles div.product-card {
    border-radius: 15px;
    padding: 1rem;
    width: 300px;
    border: 1px solid var(--border-color);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.guide-document-styles div.product-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 1rem 0;
    gap: 1rem;
}

.guide-document-styles article.user-guide-chapter ol > li {
    padding: 0;
}

.guide-document-styles article.user-guide-chapter ol.step-by-step {
    counter-reset: li;
    list-style: none;
    --Counter-Radius: 40px;
    margin-top: 2rem;
}

.guide-document-styles article.user-guide-chapter ol.step-by-step li {
    margin-bottom: 1.5rem;
    position: relative;
    padding-left: 35px;
    border-left: 0.5px dashed rgba(230, 113, 14, 1);
}

.guide-document-styles ol.step-by-step li:before {

    content: counter(li);
    counter-increment: li;
    display: inline-block;
    height: var(--Counter-Radius);
    width: var(--Counter-Radius);
    line-height: var(--Counter-Radius);
    text-align: center;
    font-size: 1rem;
    border-radius: 50%;
    background: rgba(230, 113, 14, 1);
    color: white;
    margin-right: 10px;
    font-weight: bold;
    position: absolute;
    left: calc(var(--Counter-Radius) / -2);
    top: -8px;
}

.guide-document-styles article.user-guide-chapter ul {
    list-style: none;
    margin-left: -1em;
}

.guide-document-styles article.user-guide-chapter ul.simple-list {
    list-style: disc;
    margin-left: -0.5em;
}

.guide-document-styles ul.simple-list > li::marker {
    font-size: 1.35em;
    color: orange;
}

.guide-document-styles ul.simple-list > li:not(:last-child) {
    margin-bottom: 0.75em;
}

.guide-document-styles .muted {
    margin-top: 2em;
    font-size: 0.85em;
    color: gray;
}

/* Used on DR supported file types */
.guide-document-styles ul.file-type-columns {
    columns: 4 6rem;
}

.guide-document-styles strong {
    text-transform: capitalize;
}


/*
details {
    padding: 1em 0 1em 1em;
    margin-left: -1em;
    border-left: 1px solid transparent;
}

summary {
    margin-bottom: 0.25em;
    cursor: pointer;
}

detail summary > * {
    display: inline;
}

details[open] {
    border-color: black;
}
*/
