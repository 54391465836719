
.system-info-side-menu {
    --parent-color: #eaeaea;
    --parent-background-color: #7a7a7a;

    --child-color: #444444;
    --child-background: #eaeaea;
    --child-background-hover: #eaeaea;
    --child-background-selected: #e0e0e0;
    --child-border-bottom: #eaeaea;
}

.dark-theme .system-info-side-menu {
    --parent-color: #eaeaea;
    --parent-background-color: #2e3338;

    --child-color: #eaeaea;
    --child-background: #eaeaea;
    --child-background-hover: #1c2125;
    --child-background-selected: #121618;
    --child-border-bottom: #1c2125;
}


.test-results-heading {
    font-size: 20px;

    background: var(--parent-background-color);
    color: var(--parent-color);

    padding: 7px 15px;
    border-radius: 5px 5px 0 0;
}

.system-info-content-menu-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.system-info-side-menu {
    flex: 1 0;
}

.system-info-content {
    border-radius: 3px;
    flex: 4 0;
}

.system-info-side-menu-parent {
    display: flex;
    justify-content: space-between;

    background: var(--parent-background-color);
    color: var(--parent-color);

    padding: 10px 15px;
    border-radius: 5px 5px 0 0;
}

.system-info-side-menu-parent:hover {
    cursor: pointer;
}

.system-info-side-menu-parent div:nth-child(1) {
    flex: 0 0;
}

.system-info-side-menu-parent div:nth-child(2) {
    flex: 5 0;
}

.system-info-side-menu-parent div:nth-child(3) {
    flex: 1 0;
    text-align: right;
    font-size: 13px;
}

.system-info-side-menu-parent div:nth-child(3) svg {
    flex: 1 0;
    text-align: right;
    font-size: 13px;
}

.system-info-side-menu-parent svg {
    font-size: 22px;
    margin-right: 10px;
}

.system-info-side-menu-parent-open {

}

.system-info-side-menu-parent-closed {
    border-radius: 5px;
}

.system-info-side-menu-children {
    max-height: 0;
    transition: max-height 300ms ease-out;
    overflow: hidden;
}

.system-info-side-menu-children-open {
    overflow: hidden;

    max-height: 500px;
    transition: max-height 300ms ease-in;
}

.system-info-side-menu-child {
    display: flex;
    justify-content: space-between;

    color: var(--child-color);
    text-decoration: none !important;
    padding: 5px 5px 5px 40px;
    border-bottom: 1px solid var(--child-border-bottom);
}

.system-info-side-menu-child:last-child {
    border-bottom: 3px solid var(--child-border-bottom);
}

.system-info-side-menu-child:hover {
    color: var(--child-color);
    background: var(--child-background-hover);
    cursor: pointer;
}

.system-info-side-menu-child.selected {
    background: var(--child-background-selected);
}

.system-info-side-menu-child div:nth-child(1) {
    flex: 0 0;
}

.system-info-side-menu-child div:nth-child(2) {
    flex: 5 0;
}

.system-info-side-menu-child div:nth-child(3) {
    flex: 1 0;
    text-align: right;
    font-size: 13px;
}

.system-info-page-content.no-data {
    flex: 1 0 calc(50% - 15px);
    margin-left: 0;
    margin-bottom: 0;

    border-radius: 3px;
    border: 1px solid var(--system-info-block-border);
    padding: 9px 15px;
    background: var(--system-info-block-bg);
}


.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    -webkit-align-self: flex-start;
    align-self: flex-start
}

.system-info-page {
    --system-info-heading-text: #eaeaea;
    --system-info-heading22-text: #2e3338;
    --system-info-heading-background: #7a7a7a;
    --system-info-block-border: #2e3338;
    --system-info-block-bg: #f8f8f8;
}

.dark-theme .system-info-page {
    --system-info-heading-text: #eaeaea;
    --system-info-heading22-text: #eaeaea;
    --system-info-heading-background: #2e3338;
    --system-info-block-border: #2e3338;
    --system-info-block-bg: #1c2125;
}

.system-info-page-heading {
    font-size: 20px;

    background: var(--system-info-heading-background);
    color: var(--system-info-heading-text);

    padding: 7px 15px;
    border-radius: 5px 5px 0 0;
}

.system-info-page-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: -15px;
}


.system-info-page-content-block-heading {
    display: flex;
}

.system-info-page-content-block-heading img {
    width: 54px;
}

.system-info-page-content-block-heading .h22 {
    font-size: 22px;
    line-height: 26px;
    color: var(--system-info-heading22-text)
}

.text-orange {
    color: #7e6040;
}

.bg-orange {
    background: #7e6040;
    color: #f6d4b3;
}

.border-orange {
    border: 1px solid #d3cdc5;
}

.dark-theme .border-orange {
    border: 1px solid #46382a;
}


.device-in-loop {
    flex: 1 0 calc(50% - 30px);
    margin: 5px 15px;
}

.system-info-row {
    padding: 5px 15px;
    margin-bottom: 5px;
    border-radius: 3px;
    background: var(--system-info-td-bg);
    display: flex;
}

.system-info-row .drive-number {
    font-size: 32px;
    line-height: 32px;
    font-style: italic;
    font-weight: bolder;
    margin-right: 20px;
    color: var(--system-info-numbering);
}

.system-info-row .drive-number-no-margin {
    font-size: 32px;
    line-height: 32px;
    font-style: italic;
    font-weight: bolder;
    color: var(--system-info-numbering);
}

