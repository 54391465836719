
.preview-company-logo {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
}

.preview-company-logo div > div {
    background-repeat: no-repeat;
    background-position: center center;
    width: 80%;
    height: 80%;
}

.preview-company-logo .contain {
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.upload-logo-image-wrapper {
    width: 100%;
    background: #e3e3e3;
    margin-bottom: 15px;
    border-radius: 3px;
    border: 1px solid #cccccc;
}

.upload-logo-custom-input {

}

.logo-preview-corners {
    position: relative;
    width: 300px;
    height: 185px;
    background-color: white;
    border: 1px solid black;
}

.logo-preview-corners:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    border: 1px solid #fff;
    height: 100%;
    width: 100%;
    border-radius: 10%;
}

.logo-preview-corners:after {
    content: '';
    position: absolute;
    bottom: -1px;
    right: -1px;
    border: 1px solid #fff;
    height: 100%;
    width: 100%;
    border-radius: 10%;
}
