.header-drop-down {
    position: absolute;
    top: 66px;
    right: 0;
    border: 1px solid #c1c5c8;
    background: white;
    z-index: 1;
    min-width: 200px;
    padding: 15px;
    border-radius: 3px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
}
