.notifications {
    width: 45px;
    height: 45px;
    cursor: pointer;
    border-radius: 3px;
}

.notifications:hover {
    background: #eaeaea;
}

.notifications .badge {
    z-index: 1;
    min-width: 20px;
}

.notifications-active {
    background: #eaeaea;
}