.downloads {
    --donwloads-section-h4: #444444;
    --downloads-table-border-color: #cccccc;
    --downloads-table-th-bg-color: #ffffff;
    --downloads-table-td-bg-color-odd: #d3f8d3;
    --downloads-table-td-bg-color-even: #e9ffe9;
    --downloads-table-td-item-dashed-border-bottom: #cccccc;
}

.dark-theme .downloads {
    --donwloads-section-h4: #eaeaea;
    --downloads-table-border-color: #15181a;
    --downloads-table-th-bg-color: #212529;
    --downloads-table-th-color: #eaeaea;
    --downloads-table-td-bg-color-odd: #1e2125;
    --downloads-table-td-bg-color-even: #2a2f34;
    --downloads-table-td-item-dashed-border-bottom: #383e44;
}

.donwloads-section h4 {
    color: var(--donwloads-section-h4);
}

.donwloads-section {
    margin-bottom: 30px;
}

.downloads-table {
    border: 1px solid var(--downloads-table-border-color);
    border-radius: 5px;
    overflow: auto;
}

.downloads-table-th,
.downloads-table-td,
.downloads-table-td-no-data {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    padding: 5px 15px;
    background: var(--downloads-table-th-bg-color);
    align-self: stretch;
    color: var(--downloads-table-th-color);
    min-height: 40px;
}

.downloads-table-td-no-data {
    grid-template-columns: 1fr;
}

.downloads-table-td:nth-child(odd),
.downloads-table-td-no-data:nth-child(odd) {
    background: var(--downloads-table-td-bg-color-odd);
}

.downloads-table-td:nth-child(even),
.downloads-table-td-no-data:nth-child(even) {
    background: var(--downloads-table-td-bg-color-even);
}

.downloads-table-item {

}

.downloads-table-th-item-alt {
    display: none;
}

.download-table-td-parent {
    display: grid;
    align-self: center;
}

@media (max-width: 1000px) {
    .downloads-table-th {
        display: none;
    }

    .downloads-table-td {
        display: block;
    }

    .downloads-table-td-item,
    .downloads-table-th-item-alt {
        padding: 5px 0;
        display: inline-grid;
        border-bottom: 1px dashed var(--downloads-table-td-item-dashed-border-bottom);
    }

    .download-table-td-parent {
        grid-template-columns: 1fr 2fr;
    }

    .download-table-td-parent:last-child .downloads-table-td-item,
    .download-table-td-parent:last-child .downloads-table-th-item-alt {
        border: none;
    }
}