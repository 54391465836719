.portal-alerts {
    position: fixed;
    top: 110px;
    right: 20px;
    z-index: 999;
}

.portal-alert {
    position: relative;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    width: 320px;
    min-height: 100px;

    box-shadow: -1px -1px 22px -1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: -1px -1px 22px -1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: -1px -1px 22px -1px rgba(0, 0, 0, 0.25);
}

.portal-alert-title {
    font-weight: 600;
    text-transform: capitalize;
}

.portal-alert-title-icon {
    margin-right: 5px;
    position: relative;
    top: -5px;
    font-size: 20px;
}

.portal-alert-success,
.portal-alert-success .badge {
    color: #ffffff;
}

.portal-alert-danger,
.portal-alert-danger .badge {
    color: #ffffff;
}

.portal-alert-warning,
.portal-alert-warning .badge {
    color: #444444;
}

.portal-alert-info,
.portal-alert-info .badge {
    color: #ffffff;
}

.portal-alert-exit-active {
    opacity: 0;
    transition: opacity 700ms ease-out;
}