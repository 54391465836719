.user-login-sessions {
    --user-login-sessions-numbering: #bebebe;
    --user-login-sessions-td-bg: #ffffff;
    --user-login-sessions-td-border: #eaeaea;
    --user-login-sessions-td-color: #444444;
}

.dark-theme .user-login-sessions {
    --user-login-sessions-numbering: #2a211d;
    --user-login-sessions-td-bg: #2e3338;
    --user-login-sessions-td-border: #2e3338;
    --user-login-sessions-td-color: #CCBCB5;
}

.user-login-sessions-h {
    font-size: 1.5rem;
    color: var(--user-login-sessions-td-color);
}

.user-login-session {
    display: grid;
    grid-template-columns: 1fr 5fr 2fr 2fr 2fr;
    gap: 15px;
    align-items: center;

    min-width: 1200px;
    background: var(--user-login-sessions-td-bg);
    border: 1px solid var(--user-login-sessions-td-border);
    color: var(--user-login-sessions-td-color);
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 5px;

    box-shadow: 0 0 9px -4px rgba(48, 51, 17, 0.50);
    -webkit-box-shadow: 0 0 9px -4px rgba(48, 51, 17, 0.50);
    -moz-box-shadow: 0 0 9px -4px rgba(48, 51, 17, 0.50);
}

.user-login-session-number {
    font-size: 32px;
    line-height: 32px;
    font-style: italic;
    font-weight: bolder;
}

.user-login-session div:nth-child(2),
.user-login-session div:nth-child(3),
.user-login-session div:nth-child(4) {
    text-align: center;
}

.user-login-session div:nth-child(5) div {
    text-align: right;
}

.user-login-session-table-th-item-alt {
    display: none;
}

.current-session {
    position: relative;
    top: -3px;
    left: 7px;
    font-size: 25px;
    padding: 0;
    line-height: 12px;
    cursor: pointer;
}

@media (max-width: 1530px) {
    .user-login-session {
        display: block;
        min-width: 100%;
        position: relative;
    }

    .user-login-session-number {
        position: absolute;
        bottom: 10px;
        left: 5px;
    }

    .user-login-session-table-h {
        display: none;
    }

    .user-login-session-table-th-item-alt {
        display: block;
    }

    .user-login-session div:not(.user-login-session div div):not(.user-login-session div:nth-child(1)):not(.user-login-session div:last-child) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-bottom: 1px dashed #eaeaea;
    }

    .user-login-session div:nth-child(2),
    .user-login-session div:nth-child(3),
    .user-login-session div:nth-child(4) {
        text-align: left;
    }

    .user-login-session div:nth-child(5) div {
        padding-top: 3px;
        text-align: right;
    }

}