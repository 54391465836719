.inner-product {
    --inner-product-bg: #ffffff;
    --inner-product-image-bg: #e7e7e7;
    --inner-product-border: #c1c5c8;
    --inner-product-color: #343a40;
    --inner-product-title-bg: #eaeaea;
}

.dark-theme .inner-product {
    --inner-product-bg: #343a40;
    --inner-product-image-bg: #23272c;
    --inner-product-border: #17191c;
    --inner-product-color: #ffffff;
    --inner-product-title-bg: #343a40;

}

.inner-product {
    color: var(--inner-product-color)
}

.inner-product-expiration-date {
    padding: 0;
    font-size: 12px;
    border-radius: 0 0 0 5px;
}

.inner-product.card {
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid var(--inner-product-border);
    background: var(--inner-product-bg) no-repeat center center;
}

.inner-product-image {
    height: 150px;
    background: var(--inner-product-image-bg) no-repeat center center;
    background-size: 70%;
}

.inner-product-description {
    height: 110px;
    overflow-y: auto;
    margin-bottom: 15px;
}

.inner-product-description::-webkit-scrollbar {
    width: 10px;
}

.inner-product-description::-webkit-scrollbar-track {
    background-color: #dcdcdc;
    border-radius: 5px;
}

.inner-product-description::-webkit-scrollbar-thumb {
    border-radius: 15px;
    border: 2px solid transparent;
    background-clip: content-box;
    background-color: #a9a9a9;
}

.btn-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}