.qr-modal {
    width: 300px;
    text-align: center;
}

@media (max-width: 600px) {
    .qr-modal {
        width: auto;
    }
}

.qr-clickable {
    width: 100%;
    cursor: pointer;
}

.qr-clickable:hover {
    color: #e34600;
}