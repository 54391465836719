.system-info-page {
    --system-info-heading-text: #eaeaea;
    --system-info-heading-background: #7a7a7a;
    --system-info-block-border: #2e3338;
    --system-info-block-bg: #f8f8f8;
}

.dark-theme .system-info-page {
    --system-info-heading-text: #eaeaea;
    --system-info-heading-background: #2e3338;
    --system-info-block-border: #2e3338;
    --system-info-block-bg: #1c2125;
}

.system-info-page-heading {
    font-size: 20px;

    background: var(--system-info-heading-background);
    color: var(--system-info-heading-text);

    padding: 7px 15px;
    border-radius: 5px 5px 0 0;
}

.system-info-page-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: -15px;
}

.system-info-page-content-block {
    flex: 1 0 calc(50% - 15px);
    margin-left: 15px;
    margin-bottom: 15px;

    border-radius: 3px;
    border: 1px solid var(--system-info-block-border);
    padding: 15px;
    background: var(--system-info-block-bg);
}

@media (max-width: 1200px) {
    .system-info-page-content-block {
        flex: 0 0 calc(100% - 15px);
    }
}

.system-info-page-content-block-heading {
    display: flex;
}

.system-info-page-content-block-heading img {
    width: 54px;
}

.system-info-page-content-block-heading .h22 {
    font-size: 22px;
    line-height: 26px;
}

.SMBIOS-info .system-info-page-content-block-heading img {
    width: 80px;
}

.SMBIOS-info .system-info-page-content-block-heading .h22 {
    font-size: 22px;
    line-height: 26px;
}

.text-orange {
    color: #7e6040;
}

.monospaced-value {
    font-family: monospace;
    font-size: 14px;
    word-break: break-all;
}

.badge-orange {
    background: #7e6040;
    color: #f6d4b3;
}

.border-orange {
    border: 1px solid #d3cdc5;
}

.dark-theme .border-orange {
    border: 1px solid #46382a;
}

.block-w100 {
    flex: 0 0 calc(100% - 15px);
}

.devices-loop {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -9px;
}

@media (max-width: 1200px) {
    .devices-loop {
        display: block;
    }
}

.device-in-loop {
    flex: 1 0 calc(50% - 30px);
    margin: 5px 15px;
}

.system-info-row {
    padding: 5px 15px;
    margin-bottom: 5px;
    border-radius: 3px;
    background: var(--system-info-td-bg);
    display: flex;
}

.system-info-row .drive-number {
    font-size: 32px;
    line-height: 32px;
    font-style: italic;
    font-weight: bolder;
    margin-right: 20px;
    color: var(--system-info-numbering);
}

.system-info-row .drive-number-no-margin {
    font-size: 32px;
    line-height: 32px;
    font-style: italic;
    font-weight: bolder;
    color: var(--system-info-numbering);
}

.disk-partitions {
    gap: 15px;
}

.ram-hover-raw-data-names {
    color: #f6d4b3;
}

.ram-hover-raw-data {
    background: #7e6040;
    color: #f6d4b3;

    position: absolute;
    top: 5px;
    right: 5px;
}

.ram-hover-raw-data:hover {
    color: #f6d4b3;
    background: #64492c;
}

#smbios-ram-tooltip .tooltip-inner {
    max-width: 500px;
}