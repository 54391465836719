.login-page {
    background: #c1c5c8;
}

.login-page-form-wrapper {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    overflow: hidden;
    background: #ffffff;
    width: 900px;
    border-radius: 5px;
    box-shadow: -1px -1px 22px -1px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: -1px -1px 22px -1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: -1px -1px 22px -1px rgba(0, 0, 0, 0.15);
}

.login-form-left {
    position: relative;
    width: 50%;
    background: no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.login-form-right {
    padding: 50px;
    width: 50%;
}

.login-form-heading {
    align-items: center;
}

.login-contact-us .btn {
    width: 50%;
}

@media (max-width: 911px) {
    .login-page-form-wrapper {
        width: calc(100% - 15px);
    }
}

@media (max-width: 600px) {
    .login-form-left {
        width: 0;
    }

    .login-form-right {
        padding: 50px;
        width: 100%;
    }

    .login-page-form-wrapper {
        height: 100%;
        width: 100%;
    }
}

.flux-gamess-credits {
    position: absolute;
    bottom: 5px;
    right: 10px;
    color: white;
}