header {
    position: relative;
    background: #ffffff;
    height: 85px;
    border-bottom: 1px solid #c1c5c8;
}

.aside-control {
    cursor: pointer;
}

.aside-control:hover {
    filter: brightness(190%);
}

.header-user-menu {

}
