.results {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.results header {
    display: flex;
}

.results header,
.results .google-map-container,
.results footer {
    flex-shrink: 0;
}

.results .google-map-container {
    flex-grow: 1;
}

.results footer {
    margin-top: auto;
    background-color: #f8f9fa;
    text-align: center;
}
