.messages {
    width: 45px;
    height: 45px;
    cursor: pointer;
    border-radius: 3px;
}

.messages:hover {
    background: #eaeaea;
}

.messages .badge {
    z-index: 1;
    min-width: 20px;
}

.messages-active {
    background: #eaeaea;
}