.line-chart {
    --line-chart-bg: #ffffff;
    --line-chart-border: #c1c5c8;
    --line-chart-color: #343a40;
}

.dark-theme .line-chart {
    --line-chart-bg: #23272c;
    --line-chart-border: #17191c;
    --line-chart-color: #ffffff;
}

.line-chart-body {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin-bottom: 15px;
}

.line-chart-body-item {
    overflow: hidden;
    height: 300px;
    background: var(--line-chart-bg);
    border: 1px solid var(--line-chart-border);
    color: var(--line-chart-color);
    border-radius: 5px;
    padding: 10px;
}

@media (max-width: 600px) {
    .line-chart-body {
        grid-template-columns: 1fr;
    }
}