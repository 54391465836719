.disabled-devices-content {
    --system-info-numbering: #bebebe;
    --system-info-td-bg: #eaeaea;
    --system-info-block-border:#eaeaea;
    --logical-drive-bg: #CCBCB5;
}

.dark-theme .disabled-devices-content {
    --system-info-td-bg: #2e3338;
    --system-info-block-border:#2e3338;
    --logical-drive-bg: #2a211d;

}

.disabled-device-description{
    font-size:20px;
    font-weight:bold;
    line-height:23px;
    color: rgba(43, 128, 0, 0.91);
}

.disabled-devices-content .device-in-loop{
    flex: 1 0 50%;
}

.dark-theme .disabled-devices-content .system-info-page-content-block-heading img{
    filter: invert(25%);
}