.feedback {
    --feedback-texts-color: #444444;
    --form-element-bg: #ffffff;
}

.dark-theme .feedback {
    --feedback-texts-color: #eaeaea;
    --form-element-bg: #444444;
}


.feedback-main-h5,
.feedback-subject,
.feedback-message,
.feedback-under-subject-text {
    color: var(--feedback-texts-color);
}
