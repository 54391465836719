@font-face {
    font-family: 'Blinker';
    src: url('../assets/fonts/Blinker-Black.woff2') format('woff2'),
    url('../assets/fonts/Blinker-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Blinker';
    src: url('../assets/fonts/Blinker-Thin.woff2') format('woff2'),
    url('../assets/fonts/Blinker-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Blinker';
    src: url('../assets/fonts/Blinker-ExtraLight.woff2') format('woff2'),
    url('../assets/fonts/Blinker-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Blinker';
    src: url('../assets/fonts/Blinker-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/Blinker-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Blinker';
    src: url('../assets/fonts/Blinker-Regular.woff2') format('woff2'),
    url('../assets/fonts/Blinker-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Blinker';
    src: url('../assets/fonts/Blinker-ExtraLight.woff2') format('woff2'),
    url('../assets/fonts/Blinker-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Blinker';
    src: url('../assets/fonts/Blinker-Bold.woff2') format('woff2'),
    url('../assets/fonts/Blinker-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

.main-content {
    --main-content-color: #454d55;
    --main-content-background: #eef0f3;
    --main-text-color: #444444;
    --dashed-border-color: #444444;
    --dashed-border-opacity: 0.2;
}

.dark-theme .main-content {
    --main-content-color: #eaeaea;
    --main-content-background: #454d55;
    --main-text-color: #eaeaea;
    --dashed-border-color: #eaeaea;
    --dashed-border-opacity: 0.2;
}

.main-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: Blinker, serif;
    color: var(--main-content-color);
    /*NEED TO BE FIXED*/
}

main {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.main-content {
    flex: 1 0 auto;
    background: var(--main-content-background);
}

.main-content-loading {
    margin: 15px;
    color: var(--main-content-color);
}

.cursor-pointer {
    cursor: pointer;
}

.page-main-heading {
    color: var(--main-content-color);
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}