.events {
    --system-info-numbering: #bebebe;
    --system-info-td-bg: #eaeaea;
    --system-info-block-border: #eaeaea;
    --logical-drive-bg: #CCBCB5;
}

.dark-theme .events {
    --system-info-td-bg: #2e3338;
    --system-info-block-border: #2e3338;
    --logical-drive-bg: #2a211d;
}

.events .system-info-page-content-block {
    flex: 1 0 100%;
    margin-left: 0;
    margin-bottom: 0;

    border-radius: 3px;
    border: none;
    padding: 0;
    background: none;
}

.events .device-loop {
    margin-top: -5px;
}

.events .system-info-td,
.events .system-info-th {
    flex: 1 0 auto;
}

.events .system-info-td:nth-child(1),
.events .system-info-th:nth-child(1) {
    flex: 0 0;
}

.events .system-info-th {
    font-size: 23px;
}

.events .device-in-loop {
    margin: 5px 0 5px 15px;
    flex: 1 0 100%;
}

.device-in-loop-parts {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
}

.device-in-loop-parts div {
    flex: 0 0 15%;
    text-align: center;
}

.device-in-loop-parts div:nth-child(1) {
    flex: 1;
    font-weight: bold;
    text-align: left;
}

.events .events-column-heading {
    cursor: pointer;
    position: relative;
}

.events .events-column-heading:hover {
    opacity: 60%;
}

.events .events-column-heading:active {
    opacity: 80%;
}

.event-heading-wrapper {
    position: relative;
}

.events-sorting-caret:first-child {

}
