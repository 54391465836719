.licenses {
    --licenses-search-bar-bg: #ffffff;
    --licenses-search-bar-border: #cccccc;
    --licenses-search-bar-color: #444444;

    --licenses-scrollbar-track: #f6f6f6;
    --licenses-scrollbar-thumb: #999999;

    --licenses-license-list-holder-bg: #efefef;
    --licenses-selected-license-bg: #343a40;
    --licenses-selected-license-color: #ffffff;
}

.dark-theme .licenses {
    --licenses-search-bar-bg: #3f474e;
    --licenses-search-bar-border: #56606a;
    --licenses-search-bar-color: #ffffff;

    --licenses-scrollbar-thumb: #3b4249;
    --licenses-scrollbar-track: #4f5760;

    --licenses-license-list-holder-bg: #343a40;
    --licenses-selected-license-bg: #dedede;
    --licenses-selected-license-color: #343a40;
}


.licenses-licenses-left {
    color: var(--licenses-search-bar-color);
}

.licenses-license-list-holder {
    border: 1px solid var(--licenses-search-bar-border);
    padding: 15px;
    border-radius: 3px;
    background: var(--licenses-license-list-holder-bg);
    box-shadow: 0 0 9px -4px rgba(48, 51, 17, 0.50);
    -webkit-box-shadow: 0 0 9px -4px rgba(48, 51, 17, 0.50);
    -moz-box-shadow: 0 0 9px -4px rgba(48, 51, 17, 0.50);
}

.licenses-search-bar .form-control,
.licenses-search-bar .btn {
    background-color: var(--licenses-search-bar-bg);
    border: 1px solid var(--licenses-search-bar-border);
    color: var(--licenses-search-bar-color);
}

.licenses-filtered {
    width: 100%;
    max-height: 250px;
    overflow: auto;
    background-color: var(--licenses-search-bar-bg);
    border: 1px solid var(--licenses-search-bar-border);
    color: var(--licenses-search-bar-color);
    border-radius: 3px;
}

.licenses-filtered > * > * {
    text-decoration: none;
    color: var(--licenses-search-bar-color);
}

.licenses-filtered-inner {

}

.licenses-filtered::-webkit-scrollbar {
    width: 10px;
}

.licenses-filtered::-webkit-scrollbar-track {
    background-color: var(--licenses-scrollbar-track);
}

.licenses-filtered::-webkit-scrollbar-thumb {
    border-radius: 15px;
    border: 2px solid transparent;
    background-clip: content-box;
    background-color: var(--licenses-scrollbar-thumb);
}

.licenses-filtered-license {
    padding: 5px 15px;
}


.licenses-filtered-license:not(.licenses-filtered-license-heading):hover {
    cursor: pointer;
    background-color: var(--licenses-search-bar-border);
}

.licenses-selected-license {
    background: var(--licenses-selected-license-bg) !important;
    color: var(--licenses-selected-license-color) !important;
}

.licenses-filtered-license-heading {
    font-weight: bold;
}

.licenses-license-content {
    flex: 1 0;
}

.licenses-license-content .btn {
    white-space: nowrap;
    padding: 0;
    cursor: not-allowed;
}

.licenses-license-name-fixed-size,
.licenses-license-admin-fixed-size {
    flex: 0 0 150px;
}

.licenses-license-usage-fixed-size {
    flex: 0 0 40px;
}

.licenses-license-numbers-fixed-size {
    flex: 0 0 20px;
}

.licenses-license-icons-fixed-size {
    flex: 0 0 30px;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media (max-width: 600px) {

    .licenses-license-content:nth-child(5), .licenses-license-content:nth-child(6) {
        display: none !important;
    }


}