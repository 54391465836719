.analysis-test-results {
    --success-color: #28a745;
    --failed-color: #dc3545;

    --parent-color: #eaeaea;
    --parent-background-color: #7a7a7a;

    --child-color: #444444;
    --child-background: #eaeaea;
    --child-background-hover: #eaeaea;
    --child-border-bottom: #eaeaea;
    --dashed-border-bottom:#eaeaea;
}

.dark-theme .analysis-test-results {
    --success-color: #28a745;
    --failed-color: #dc3545;

    --parent-color: #eaeaea;
    --parent-background-color: #2e3338;

    --child-color: #eaeaea;
    --child-background: #eaeaea;
    --child-background-hover: #1c2125;
    --child-border-bottom: #1c2125;
    --dashed-border-bottom: #3f4e56;
}

.analysis-test-results-heading {
    font-size:20px;

    background: var(--parent-background-color);
    color: var(--parent-color);

    padding: 7px 15px;
    border-radius: 5px 5px 0 0;
}

.analysis-test-results-device {
    margin-bottom: 15px;
}

.analysis-test-results-device-heading {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--dashed-border-bottom);
}

.analysis-test-results-device-heading-part {
    align-self: center;
    padding: 5px;
}
.analysis-test-results-device-heading-part img {
    width:55px;
}

.analysis-test-results-device-heading-part:nth-child(1) {
    flex: 0 0;
}

.analysis-test-results-device-heading-part:nth-child(2) {
    flex: 1 0;
}

.analysis-test-results-device-heading-part:nth-child(3) {
    text-align: right;
    align-content: center;
    flex: 1 0;
}

.analysis-test-results-option-children {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed var(--dashed-border-bottom);
}

.analysis-test-results-option-child:first-child{
    flex: 1 1 0;
    width: 0;
    text-align: left;
}

.analysis-test-results-option-child{
    flex: 1 1 0;
    width: 0;
    text-align: right;
}


.analysis-test-results-option-child.result-success {
    color: var(--success-color);
}

.analysis-test-results-option-child.result-failed {
    color: var(--failed-color);
}

.btn-analysis {
    --btn-bg: #f5ab00;
    --btn-border: #d98f00;
}
.btn-analysis{
    background: var(--btn-bg);
    border:1px solid var(--btn-border);
    border-radius: 3px;
    padding:4px 10px;
    color:white;
}
.btn-analysis:hover{
    text-decoration: none;
    color: #f1f1f1;
}


.analysis-test-results-device-checkbox{
    width:16px;
    height:16px;
    position: relative;
    top:2px;
    display:inline-block;
    border:1px solid #cccccc;
    border-radius: 3px;
}

.analysis-test-results-device-checkbox.analysis-test-results-device-checkbox-checked{
    background: #ff5252;
    border:1px solid #ff5252;
}

.analysis-test-results-device-checkbox svg{
    position:absolute;
    width: 10px;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
}

.analysis-test-results-device-checkbox svg path{
    fill: #ffffff;
}
