footer {
    display: flex;
    justify-content: space-between;

    border-top: 1px solid #c1c5c8;
    background: #ffffff;
    height: 50px;
    flex-shrink: 0;
}


.footer-copyright {
    flex: 1 0;
    max-width: 100%;
    line-height: 19px;
}

.feedback-btn {
    margin-left: 15px;
}

@media (max-width: 1000px) {
    .feedback-btn-text {
        display: none;
    }
}