.puzzle15-wrapper {
    position: relative;
    width: 350px;
    height: 400px;
}

@media (max-width: 600px) {
    .puzzle15-wrapper {
        width: 100%;
    }
}

.puzzle15 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.puzzle15-wrapper .btn {
    box-shadow: none !important
}

.puzzle15-number {
    position: relative;
    flex: 1 calc(25% - 4px);
    justify-content: center;
    display: flex;
    gap: 5px;
}

.puzzle15-number-text {
    flex: 0 0 calc(25% - 4px);
    position: relative;
    background: #de7044;
    border-radius: 6px;
}

.puzzle15-number-text-digit {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 35px;
    color: #045600;
}

.puzzle15-number-animate {
    height: 100%;
    background: #6dd35d;
    border-radius: 5px;
}

.puzzle15-number-zero {
    background: #de7044;
}

.puzzle15-number-animate-left {
    position: relative;
    transition: all 100ms;
    transform: translate(calc(-100% - 5px), 0);
    z-index: 999;
}

.puzzle15-number-animate-right {
    position: relative;
    transition: all 100ms;
    transform: translate(calc(100% + 5px), 0);
    z-index: 999;
}

.puzzle15-number-animate-top {
    position: relative;
    transition: all 100ms;
    transform: translate(0, calc(-100% - 5px));
    z-index: 999;
}

.puzzle15-number-animate-bottom {
    position: relative;
    transition: all 100ms;
    transform: translate(0, calc(100% + 5px));
    z-index: 999;
}

@-webkit-keyframes left {
    0% {
        left: 0;
    }
    100% {
        left: 100px;
    }
}

.puzzle15-controls {
    display: flex;
    gap: 5px;
}