.aside-menu {

}

.aside-menu-cat {

}

.aside-menu-cat-item {
    height: 40px;
    border-radius: 5px;
    padding-left: 15px;
    cursor: pointer;
    color: #c2c7d0;
    text-decoration: none;
}

.aside-menu-cat-item:hover {
    background: #4b545c;
    cursor: pointer;
    color: #c2c7d0;
}

.aside-menu-cat-item-icon {
    margin-right: 15px;
    font-size: 22px;
    position: relative;
    top: -3px;
}

.aside-menu-cat-item-title {

}