.os-configuration {
    --system-info-numbering: #bebebe;
    --system-info-td-bg: #eaeaea;
    --system-info-block-border:#eaeaea;
    --logical-drive-bg: #CCBCB5;
}

.dark-theme .os-configuration {
    --system-info-td-bg: #2e3338;
    --system-info-block-border:#2e3338;
    --logical-drive-bg: #2a211d;
}

.os-configuration .system-info-page-content-block{
    flex:1 0 100%;
    margin-left:0;
    margin-bottom:0;

    border-radius:3px;
    border:none;
    padding:0;
    background: none;
}
.os-configuration .device-loop{
    margin-top: -5px;
}

.os-configuration .system-info-td,
.os-configuration .system-info-th{
    flex: 1 0 auto;
}

.os-configuration .system-info-td:nth-child(1),
.os-configuration .system-info-th:nth-child(1){
    flex: 0 0;
}

.os-configuration .system-info-th{
    font-size:23px;
}

.os-configuration .device-in-loop{
    margin: 5px 0 5px 15px;
    flex: 1 0 100%;
}

.os-configuration .device-in-loop-parts{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
}

.os-configuration .device-in-loop-parts div{
    flex: 0 0 50%;
    text-align: left;
}

.os-configuration .device-in-loop-parts div:nth-child(1){
    flex: 1;
    font-weight: bold;
    text-align: left;
}

