.tagline {
    height: 308px;
    position: relative;
}

.tagline textarea {
    margin-bottom: 12px;
    height: calc(100% - 50px);
}

.tagline button {
    width: 100%;
}

.tagline-explanation {
    position: absolute;
    left: 5px;
    bottom: 55px;
    width: 70%;
}