.checkup-test-results, .checkup {
    --checkup-parent-flex-bg: #f3f3f3;
    --checkup-parent-flex-border: #dcdcdc;
    --checkup-progress-bar-bg: #cccccc;

    --success-color: #28a745;
    --failed-color: #dc3545;

    --parent-color: #eaeaea;
    --parent-background-color: #7a7a7a;

    --child-color: #444444;
    --child-background: #eaeaea;
    --child-background-hover: #eaeaea;
    --child-border-bottom: #eaeaea;
    --dashed-border-bottom:#eaeaea;
}

.dark-theme .checkup-test-results, .dark-theme .checkup {
    --checkup-parent-flex-bg: #141a1f;
    --checkup-progress-bar-bg: #cccccc;
    --checkup-parent-flex-border: #091219;

    --success-color: #28a745;
    --failed-color: #dc3545;

    --parent-color: #eaeaea;
    --parent-background-color: #2e3338;

    --child-color: #eaeaea;
    --child-background: #eaeaea;
    --child-background-hover: #1c2125;
    --child-border-bottom: #1c2125;
    --dashed-border-bottom: #3f4e56;
}

.checkup-parent-flex{
    background:var(--checkup-parent-flex-bg);
    border:1px solid var(--checkup-parent-flex-border);
    border-radius:5px;
}

.checkup-test-results-heading {
    font-size:20px;

    background: var(--parent-background-color);
    color: var(--parent-color);

    padding: 7px 15px;
    border-radius: 5px 5px 0 0;
}

.checkup-device-image {
    width: 50px;
    height: 46px;
}

.checkup-device-image img {
    width: 50px;
}

.checkup-progress-bar {
    margin: 0 auto;
    background: var(--checkup-progress-bar-bg);
}

.checkup-flex-item {
    flex: 1 1 0;
    -ms-flex-item-align: center !important;
    align-self: center !important;
}

.checkup-flex-item:first-child {
    flex: 0 0 0;
}

.checkup-global-status-canceled{
    color:orange;
}
.checkup-global-status-passed{
    color:green;
}
.checkup-global-status-failed{
    color:red;
}