.qtw {
    --qtw-item-bg: #ffffff;
    --qtw-item-border: #c1c5c8;
    --qtw-column-color: #343a40;
    --qtw-column-title-bg: #eaeaea;


    --qtw-scrollbar-track: #e34600;
    --qtw-scrollbar-thumb: #f5f5f5;
}

.dark-theme .qtw {
    --qtw-item-bg: #23272c;
    --qtw-item-border: #17191c;
    --qtw-column-color: #ffffff;
    --qtw-column-title-bg: #343a40;


    --qtw-scrollbar-thumb: #3b4249;
    --qtw-scrollbar-track: #4f5760;
}

.qtw .system-info-page-heading {
    background: linear-gradient(-50deg, #e0e0e0 15%, #7a7a7a 15%);
    color: white;
}

.qtw .system-info-page-heading img {
    height: 30px;
}

.qtw-badge-statuses .badge {
    font-weight: normal;
    margin-right: 5px;
}

.qtw-badge-statuses .badge b {
    font-weight: 600;
}

.qtw-result-parent {
    display: flex;
    justify-content: space-between;
}

.qtw-result-parent.parent {
    background: var(--qtw-item-bg);
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 3px;

    box-shadow: 1px 1px 11px -1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 1px 1px 11px -1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 1px 1px 11px -1px rgba(0, 0, 0, 0.2);
}

.qtw-result-parent.parent:last-child {
    margin-bottom: 0;
}

.qtw-result-child {
    flex: 1 0;
    align-self: center;
}

.qtw-vertical-scroller {
    height: 100%;
    max-height: 80px;
    overflow: auto;
}

.qtw-vertical-scroller::-webkit-scrollbar {
    width: 10px;
}

.qtw-vertical-scroller::-webkit-scrollbar-track {
    background-color: var(--qtw-scrollbar-track);
    border-radius: 3px;
}

.qtw-vertical-scroller::-webkit-scrollbar-thumb {
    border-radius: 3px;
    border: 2px solid transparent;
    background-clip: content-box;
    background-color: var(--qtw-scrollbar-thumb);
}

.qtw-no-image {
    border: 1px solid var(--qtw-item-border);
    width: 70px;
    height: 70px;
    float: left;
    position: relative;
}
