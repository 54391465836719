.puzzle15-timer {
    display: flex;
    gap: 5px;
}

.btn-puzzle15-timer {
    flex: 1 0 calc(50% - 4px);
    align-self: center;
    font-size: 13px;
    background: #e34600;
    color: white;
}

.puzzle15-timer-timer {
    flex: 1 0 calc(50% - 4px);
    align-self: center;
    font-size: 13px;
    color: white;
    background: #b93700;
}

.puzzle15-timer-timer.paused {
    background: #e1835b;
}


.btn-puzzle15-timer:hover {
    color: white;
    background: #d54302;
}

.puzzle15-timer-timer:hover {
    color: white;
    cursor: default;
}